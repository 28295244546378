import { createContext, useContext } from "react";
import { initializeApp } from "firebase/app";
import { getAuth, createUserWithEmailAndPassword, signInWithEmailAndPassword, signOut, deleteUser } from 'firebase/auth';
import {
  getFirestore, collection, addDoc, getDocs, deleteDoc, doc, updateDoc,
  query, where
} from 'firebase/firestore';

import { getStorage, ref, uploadBytes, getDownloadURL } from 'firebase/storage';

const FirebaseContext = createContext(null);

const firebaseConfig = {
  apiKey: "AIzaSyChy1NKwINxmq5OcI5_z5eHHtZLnJaTjGo",
  authDomain: "consignment-f2492.firebaseapp.com",
  projectId: "consignment-f2492",
  storageBucket: "consignment-f2492.appspot.com",
  messagingSenderId: "290558773916",
  appId: "1:290558773916:web:46fa0d365cc00347bd3b59",
  databaseUrl: "https://consignment-f2492-default-rtdb.firebaseio.com/"
};

export const useFirebase = () => useContext(FirebaseContext);

export const firebaseApp = initializeApp(firebaseConfig);
const firebaseAuth = getAuth(firebaseApp);
const firestore = getFirestore(firebaseApp);
const storage = getStorage(firebaseApp);
//const functions = getFunctions(firebaseApp);

export const FirebaseProvider = (props) => {

  const signupUser = (email, password) => {
    return createUserWithEmailAndPassword(firebaseAuth, email, password)
      .then((res) => {
        alert("User created!")
        signOut(firebaseAuth)
        return res
      })
      .catch((error) => {
        if (error.message === 'Firebase: Error (auth/email-already-in-use).') {
          alert("Email already present!")
        }
        if (error.message === 'Firebase: Password should be at least 6 characters (auth/weak-password).') {
          alert("Password should be at least 6 characters")
        }
      })
  }

  const signInUser = async (email, password) => {
    await signInWithEmailAndPassword(firebaseAuth, email, password)
  }

  const signOutUser = () => {
    console.log('You are logged out')
    signOut(firebaseAuth)
  }


  const handleAddNewClient = async (name, email, id, status, file, fileName, git) => {
    const fileRef = ref(storage, `uploads/clients/${Date.now()}-${file.name}`)
    const uploadResult = await uploadBytes(fileRef, file);
    const entryCid = randomId(6)
    await addDoc(collection(firestore, 'clients'), {
      name, email, id, status, fileName, fileUrl: uploadResult.ref.fullPath, git
    })
  }

  const onEditClientFields = async (name, clientid, id, status, file, fileName, git) => {
    const docRef = doc(firestore, "clients", clientid)

    await updateDoc(docRef, {
      name: name,
      id: id,
      status: status,
      git: git,
    });

    if (file !== undefined) {
      const fileRef = ref(storage, `uploads/clients/${Date.now()}-${file.name}`)
      const uploadResult = await uploadBytes(fileRef, file);
      await updateDoc(docRef, {
        name: name,
        id: id,
        status: status,
        fileUrl: uploadResult.ref.fullPath,
        fileName: fileName,
        git: git
      });
    } else {
      await updateDoc(docRef, {
        name: name,
        id: id,
        status: status,
        git: git
      });
    }
  }

  const getClientId = async (email) => {
    var clientData = [];
    const citiesRef = collection(firestore, "clients");
    const q = query(citiesRef, where("email", "==", email));
    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
      clientData.push(doc.data())
    });
    return clientData[0];
  }

  const getData = async (data) => {
    return await getDocs(collection(firestore, data));
  }

  const getClientName = async () => {
    var namesArr = []
    const clientRef = await getDocs(collection(firestore, "clients"))
    clientRef.forEach((doc) => {
      namesArr.push(doc.data().name)
    })
    return namesArr;
  }

  const deleteClients = async (docId) => {
    const docRef = collection(firestore, 'container', docId, 'containerClients')
    const querySnapshot = await getDocs(docRef);
    querySnapshot.docs.forEach(async (docu) => {
      const q = query(collection(firestore, "containerClients"), where("entryCid", "==", docu.data().entryCid));
      const qSnap = await getDocs(q);
      qSnap.forEach((document) => {
        console.log(document.id, " => ", document.data());
        const docRef = doc(firestore, 'containerClients', document.id)
        deleteDoc(docRef)
          .then(async () => {
            console.log("Entire Client Document has been deleted successfully.")
          })
          .catch(error => {
            console.log(error);
          })
      });
    })
  }

  const getSubCollectionData = async (custName) => {
    //return await getDocs(collection(firestore, "clients", clientId, "consignment"))
    var consignment = [];
    const consignmentRef = collection(firestore, "containerClients");
    const q = query(consignmentRef, where("name", "==", custName));
    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
      consignment.push(doc)
    });
    return consignment
  }

  const getSubContainerData = async (containerId) => {
    return await getDocs(collection(firestore, "container", containerId, "containerClients"))
  }

  const getDaybooks = async () => {
    return await getDocs(collection(firestore, "daybook"))
  }

  const getDataById = async (id) => {
    return await getDocs(collection(firestore, "daybook", id, "daybookEntries"))
  }

  const getDaybookId = async (date) => {
    var daybookId;
    const daybookRef = collection(firestore, "daybook");
    const q = query(daybookRef, where("date", "==", date));
    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
      daybookId = doc.id
    });
    return daybookId;
  }

  const getDaybookData = async (date, custName) => {
    const daybookId = await getDaybookId(date, custName)
    return await getDocs(collection(firestore, "daybook", daybookId, "daybookEntries"))
  }

  const deleteDaybookDocument = async (entryId, date, custName) => {
    const daybookId = await getDaybookId(date, custName)
    const docRef = doc(firestore, "daybook", daybookId, "daybookEntries", entryId)
    deleteDoc(docRef)
      .then(() => {
        console.log("Entire Document has been deleted successfully.")
      })
      .catch(error => {
        console.log(error);
      })
  }

  const deleteSubDocuments = async (collect, docId, subcollect) => {
    const docRef = collection(firestore, collect, docId, subcollect)
    const querySnapshot = await getDocs(docRef);
    querySnapshot.docs.forEach((docu) => {
      let delRef = doc(firestore, collect, docId, subcollect, docu.id)
      deleteDoc(delRef)
        .then(async () => {
          console.log("Entire Document has been deleted successfully.")
        })
        .catch(error => {
          console.log(error);
        })
    });
  }

  const deleteDocument = (collect, docId, subcollect, subId) => {
    let docRef;
    if (subcollect === undefined && subId === undefined)
      docRef = doc(firestore, collect, docId)
    else if (subcollect !== undefined && subId === undefined) {
      deleteSubDocuments(collect, docId, subcollect)
      docRef = doc(firestore, collect, docId)
    } else {
      docRef = doc(firestore, collect, docId, subcollect, subId);
    }
    deleteDoc(docRef)
      .then(() => {
        console.log("Entire Document has been deleted successfully.")
      })
      .catch(error => {
        console.log(error);
      })
  }

  const checkEmailAvailability = async (email) => {
    var clientData = [];
    const citiesRef = collection(firestore, "clients");
    const q = query(citiesRef, where("email", "==", email));
    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
      clientData.push(doc)
    });
    return clientData;
  }

  const checkNameAvailability = async (name) => {
    var clientData = [];
    const citiesRef = collection(firestore, "clients");
    const q = query(citiesRef, where("name", "==", name));
    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
      clientData.push(doc)
    });
    return clientData;
  }

  const getEmailWithName = async (name) => {
    var email = [];
    const citiesRef = collection(firestore, "clients");
    const q = query(citiesRef, where("name", "==", name));
    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
      email = doc.data().email
    });
    return email;
  }

  const getDateChanged = async (date, containerId) => {
    const docRef = doc(firestore, "container", containerId)
    console.log(docRef)
    await updateDoc(docRef, { date })
      .then(() => {
      })
  }


  const updateClientDOA = async (newDateOfArrival, containerName) => {
    const querySnapshot = await getDocs(collection(firestore, 'containerClients'));

    for (const doc of querySnapshot.docs) {
      const data = doc.data();
      if (data.containerName && data.containerName === containerName) {
        try {
          await updateDoc(doc.ref, { dateOfArrival: newDateOfArrival });
          console.log("DateOfarrival updated successfully for", containerName);
        } catch (error) {
          console.error("Error updating document: ", error);
        }
      }
    }
  };


  const getNumberChanged = async (container, containerId) => {
    const docRef = doc(firestore, "container", containerId)
    await updateDoc(docRef, { container })
      .then(() => {
      })
  }

  const onEditDaybook = async (custName, pdate, particulars, paid, rdate, mode, received, date, entryId) => {
    var daybookId = await getDaybookId(date, custName)
    const docRef = doc(firestore, "daybook", daybookId, "daybookEntries", entryId)
    await updateDoc(docRef, {
      custName: custName,
      pdate: pdate,
      particulars: particulars,
      paid: paid,
      rdate: rdate,
      mode: mode,
      received: received
    });
  }

  const randomId = function (length = 6) {
    return Math.random().toString(36).substring(2, length + 2);
  };

  const handleCreateNewContainerClients = async (name, email, id, size, weight, noc, file, remarks, fileName, containerId, dateOfArrival, containerName) => {
    const fileRef = ref(storage, `uploads/container/${Date.now()}-${file.name}`)
    const uploadResult = await uploadBytes(fileRef, file);
    const entryCid = randomId(6)
    await addDoc(collection(firestore, "container", containerId, "containerClients"), {
      name, email, id, size, weight, noc, fileName,
      fileUrl: uploadResult.ref.fullPath, remarks, dateOfArrival, containerName, entryCid
    })
    await addDoc(collection(firestore, "containerClients"), {
      name, email, id, size, weight, noc, fileName,
      fileUrl: uploadResult.ref.fullPath, remarks, dateOfArrival, containerName, entryCid
    })
  }

  const onEditContainerFields = async (name, id, size, weight, noc, file, remarks, fileName, clientId, containerId, entryCid) => {
    const docRef = doc(firestore, "container", clientId, "containerClients", containerId)
    if (file !== undefined) {
      const fileRef = ref(storage, `uploads/container/${Date.now()}-${file.name}`)
      const uploadResult = await uploadBytes(fileRef, file);
      await updateDoc(docRef, {
        name: name,
        id: id,
        size: size,
        weight: weight,
        noc: noc,
        fileUrl: uploadResult.ref.fullPath,
        remarks: remarks,
        fileName: fileName
      });
    } else {
      await updateDoc(docRef, {
        name: name,
        id: id,
        size: size,
        weight: weight,
        noc: noc,
        remarks: remarks,
      });
    }
    updateContainerData(name, id, size, weight, noc, file, remarks, fileName, clientId, containerId, entryCid)
  }

  const updateContainerData = async (name, id, size, weight, noc, file, remarks, fileName, clientId, containerId, entryCid) => {
    var tempId;
    const q = query(collection(firestore, "containerClients"), where("entryCid", "==", entryCid));
    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
      console.log(doc.id, " => ", doc.data());
      tempId = doc.id
    });
    if (file !== undefined) {
      const fileRef = ref(storage, `uploads/container/${Date.now()}-${file.name}`)
      const uploadResult = await uploadBytes(fileRef, file);
      const tempRef = doc(firestore, "containerClients", tempId);
      await updateDoc(tempRef, {
        name: name,
        id: id,
        size: size,
        weight: weight,
        noc: noc,
        fileUrl: uploadResult.ref.fullPath,
        remarks: remarks,
        fileName: fileName
      });
    } else {
      const tempRef = doc(firestore, "containerClients", tempId);
      await updateDoc(tempRef, {
        name: name,
        id: id,
        size: size,
        weight: weight,
        noc: noc,
        remarks: remarks,
      });
    }
  }

  const handleCreateNewContainer = async (container, date, carton) => {
    await addDoc(collection(firestore, "container"), {
      container, date, carton
    })
  }

  const handleCreateNewDaybook = async (date) => {
    const daybookId = await getDaybookId(date)
    if (daybookId === undefined) {
      await addDoc(collection(firestore, "daybook"), {
        date
      })
    } else {
      alert("Record already present")
    }
  }

  const handleNewDaybookEntries = async (custName, pdate, particulars, paid, rdate, mode, received, date) => {
    const daybookId = await getDaybookId(date, custName)
    await addDoc(collection(firestore, "daybook", daybookId, "daybookEntries"), {
      custName, pdate, particulars, paid, rdate, mode, received
    })
  }

  const getFile = async (path) => {
    //const storageRef = doc(storage, url)
    return await getDownloadURL(ref(storage, path))
  }

  const clientLedgerAmount = async (ledgerAmount, custName) => {
    let clientData = [];
    const citiesRef = collection(firestore, "clients");
    const q = query(citiesRef, where("name", "==", custName));
    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
      clientData.push(doc.id)
    });
    const docRef = doc(firestore, "clients", clientData[0])
    await updateDoc(docRef, {
      ledgerAmount: ledgerAmount
    });
  }

  const deleteContainerClient = async (entryCid, item, contitem) => {
    var tempId;
    const q = query(collection(firestore, "containerClients"), where("entryCid", "==", entryCid));
    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
      console.log(doc.id, " => ", doc.data());
      tempId = doc.id
    });
    const docRef = doc(firestore, 'containerClients', tempId)
    const docRefer = doc(firestore, 'container', item.id, 'containerClients', contitem.id)
    deleteDoc(docRef)
      .then(() => {
        console.log("Entire Document has been deleted successfully.")
        deleteDoc(docRefer)
          .then(() => {
            console.log("Entire Client Document has been deleted successfully.")
          })
          .catch(error => {
            console.log(error);
          })
      })
      .catch(error => {
        console.log(error);
      })
  }

  return <FirebaseContext.Provider value={{
    signupUser, signInUser, handleAddNewClient, handleCreateNewContainer, getDateChanged, deleteContainerClient,
    getData, signOutUser, deleteDocument, getSubCollectionData, getSubContainerData, onEditClientFields,
    onEditContainerFields, getFile, handleCreateNewContainerClients, getClientId, handleCreateNewDaybook, getDaybookData,
    handleNewDaybookEntries, onEditDaybook, deleteDaybookDocument, clientLedgerAmount, getDaybooks, getDataById,
    getClientName, checkNameAvailability, checkEmailAvailability, getEmailWithName, getNumberChanged, deleteClients, updateClientDOA
  }}>{props.children}</FirebaseContext.Provider>
}