import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Form from 'react-bootstrap/Form';
import Table from 'react-bootstrap/Table';
import Accordion from 'react-bootstrap/Accordion';
import { useAccordionButton } from 'react-bootstrap/AccordionButton';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import trash from '../../assets/trash.svg';
import edit from '../../assets/pen.svg';
import { useFirebase } from '../../context/firebase';

import '../ContainerComponent/container.css'

function CustomToggle({ children, eventKey }) {
    const decoratedOnClick = useAccordionButton(eventKey, () =>
        console.log('totally custom!'),
    );

    return (
        <button
            type="button"
            style={{ border: "none", background: "none", width: "100%", minWidth: "100%" }}
            onClick={decoratedOnClick}
        >
            {children}
        </button>
    );
}

const DayBook = () => {

    const { state } = useLocation();
    const firebase = useFirebase();
    const navigate = useNavigate();

    const [custName] = useState();
    const [date, setDate] = useState(state.date);
    const [data, setData] = useState([]);
    const [daybook, setDaybook] = useState([]);
    const [ledgerAmount, setLedgerAmount] = useState([]);
    const [renderTable, setRenderTable] = useState(false);
    const [loadData, setLoadData] = useState(false);

    useEffect(() => {
        if (daybook.length <= 0) {
            firebase.getDaybooks()
                .then((doc) => {
                    setDaybook(doc.docs)
                })
        } else if (ledgerAmount.length <= 0) {
            calculateAmount();
        }
    })

    const calculateAmount = async () => {
        const namesArr = await firebase.getClientName();
        daybook.forEach((item) => {
            firebase.getDataById(item.id)
                .then((res) => {
                    setLedgerAmount([])
                    namesArr.forEach((name) => {
                    let pamount = 0, ramount = 0, tempAmt = 0;
                        res && res.docs.forEach((entry) => {
                            if (name === entry.data().custName) {
                                pamount = pamount + parseInt(entry.data().paid)
                                ramount = ramount + parseInt(entry.data().received)
                            }
                        })
                        tempAmt = pamount - ramount
                        let obj = {
                            name: name,
                            ledgerAmount: tempAmt
                        }
                        ledgerAmount.push(obj)
                    })
                })
        }) 
        finalAmount(ledgerAmount)
    }

    const finalAmount = async () => {
        firebase.getClientName()
        .then((res) => {
            res.forEach((name) => {
                let amount = 0;
                ledgerAmount.forEach((item) => {
                    if (name === item.name) {
                        amount = parseInt(amount) + parseInt(item.ledgerAmount)
                    }
                })
                firebase.clientLedgerAmount(amount, name)
                setLoadData(true)
            })
        })
    }

    const signOutUSer = () => {
        firebase.signOutUser();
        navigate("/")
    }

    const onDeleteClicked = async (item) => {
        await firebase.deleteDocument('daybook', item, 'daybookEntries')
    }

    const getTableData = (id) => {
        setRenderTable(true);
        firebase.getDataById(id)
            .then((doc) => {
                setData(doc.docs)
            })
    }

    const renderTables = (entryDate, daybookId) => {
        return (
            <div>
                <Table striped bordered hover responsive>
                    <thead>
                        <tr>
                            <th>Customer Name</th>
                            <th>Paid Trans. Date</th>
                            <th>Particulars</th>
                            <th>Paid Amount</th>
                            <th>Received Trans. Date</th>
                            <th>Particulars</th>
                            <th>Received Amount</th>
                        </tr>
                    </thead>
                    <tbody>
                        {data.length > 0 && data.map((item) => {
                            return (
                                <tr key={item.id}>
                                    <td>{item.data().custName}</td>
                                    <td>{item.data().pdate}</td>
                                    <td>{item.data().particulars}</td>
                                    <td>{item.data().paid}</td>
                                    <td>{item.data().rdate}</td>
                                    <td>{item.data().mode}</td>
                                    <td>{item.data().received}</td>
                                    <td><Button onClick={() => onEditClicked(item, entryDate)} style={{ background: "none", border: "none" }}><img src={edit} alt='Edit' /></Button></td>
                                    <td><Button onClick={() => onDeleteEntry(item.id, daybookId)} style={{ background: "none", border: "none" }}><img src={trash} alt='Delete' /></Button></td>
                                </tr>
                            )
                        })}
                    </tbody>
                </Table>
                <Button onClick={() => navigate('/daybook/addDaybook', { state: { names: state.names, entryDate, custName, click: "addClick" } })} style={{ marginRight: "10px", marginBottom: "10px" }}>Add New</Button>

            </div>
        )
    }

    const onEditClicked = (item, entryDate) => {
        navigate(`/daybook/addDaybook`, { state: { item: item.data(), entryId: item.id, date: entryDate, custName, names: state.names } })
    }

    const onDeleteEntry = async (entryId, daybookId) => {
        await firebase.deleteDocument('daybook', daybookId, 'daybookEntries', entryId)
    }

    const handleSubmit = (e) => {
        e.preventDefault()
        //console.log(e)
        firebase.handleCreateNewDaybook(date);
        firebase.getDaybooks()
            .then((doc) => {
                setDaybook(doc.docs)
            })
    }

    return (
        <div className="containerComp">
            <h2>Day Book Details</h2>
            <Button onClick={() => navigate("/table")} style={{ marginRight: "10px", marginBottom: "10px" }}>Back to Clients</Button>
            <Button className="btn btn-danger" onClick={signOutUSer} style={{ marginRight: "10px", marginBottom: "10px" }}>Log Out</Button>

            <Accordion>
                {daybook.map((item, key) => {
                    return (
                        <Card key={key} onClick={() => getTableData(item.id)}>
                            <Card.Header>
                                <CustomToggle eventKey={key}>
                                    <div>
                                        <p>Date: {item.data().date}</p>
                                        <Button onClick={() => onDeleteClicked(item.id)}>Delete</Button>
                                    </div>
                                </CustomToggle>
                            </Card.Header>
                            <Accordion.Collapse eventKey={key}>
                                <Card.Body>
                                    {(loadData && renderTable) && renderTables(item.data().date, item.id)}
                                </Card.Body>
                            </Accordion.Collapse>
                        </Card>)
                }
                )}
            </Accordion>
            <Form style={{ marginTop: "20px" }} onSubmit={handleSubmit}>
                <label style={{ marginRight: "10px" }}>Entry Date</label>
                <input type='date' style={{ marginRight: "10px" }} onChange={(e) => setDate(e.target.value)} value={date} />
                <Button variant="primary" type="submit">Add New Entry</Button>
            </Form>
        </div>
    )
}

export default DayBook;