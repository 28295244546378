import React, {useState, useEffect} from "react";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import Login from './components/LoginComponent/login';
import Customer from './components/TableComponent/tableComponent';
import Signup from './components/SignupComponent/signup';
import './App.css';
import Consignment from "./components/ConsignmentComponent/consignment";
import AddClient from "./components/TableComponent/addClient";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { firebaseApp } from "./context/firebase";
import Container from "./components/ContainerComponent/container";
import DayBook from "./components/DaybookComponent/daybook";
import AddDaybook from "./components/DaybookComponent/addNewDaybook";
import AddContainerClients from "./components/ContainerComponent/addContainerClients";
import AddContainer from "./components/ContainerComponent/addContainer";
import {Context} from './context/adminContext'


function App() {

  const [user, setUser] = useState("")
  const [isAdminUser, setAdminUser] = useState(false)

  const auth = getAuth(firebaseApp)

  useEffect(() => {
    onAuthStateChanged(auth, (user) => {
      if(user) {
        setUser(user)
        setAdminUser(user.email === "admin@gmail.com")
      } else {
        setUser(null)
        setAdminUser(false)
      }
    })
  })

  const router = createBrowserRouter([
    {
      path: "/",
      element: <Login isAdminUser={isAdminUser}/>
    },
    {
      path: "/table",
      element: (
        <div>
          {isAdminUser && <Customer user={user}/>}
        </div>
      ),
    },
    {
      path: "/signup",
      element: <Signup />,
    },
    {
      path: "/consignment",
      element: <Consignment user={user}/>,
    },
    {
      path: "/consignment/:id",
      element: <Consignment user={user}/>,
    },
    {
      path: "/table/addClient",
      element:  (
        <div>
          {isAdminUser && <AddClient user={user}/>}
        </div>
      ),
    },
    
    {
      path: "/container",
      element:  (
        <div>
          {isAdminUser && <Container user={user}/>}
        </div>
      ),
    },
    
    {
      path: "/container/addContainerClients",
      element:  (
        <div>
          {isAdminUser && <AddContainerClients user={user}/>}
        </div>
      ),
    },
    {
      path: "/container/addContainer",
      element:  (
        <div>
          {isAdminUser && <AddContainer user={user}/>}
        </div>
      ),
    },
    {
      path: "/daybook",
      element:  (
        <div>
          {isAdminUser && <DayBook user={user}/>}
        </div>
      ),
    },
    {
      path: "/daybook/addDaybook",
      element:  (
        <div>
          {isAdminUser && <AddDaybook user={user}/>}
        </div>
      ),
    },
  ]);

  return (
    <div className="app">
      <Context.Provider value={isAdminUser}>
      <RouterProvider router={router} /></Context.Provider>
    </div>
  );
}

export default App;
