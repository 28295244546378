import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import Table from 'react-bootstrap/Table';
import Button from 'react-bootstrap/Button';
import { useFirebase } from '../../context/firebase';

import './consignment.css'
import 'bootstrap/dist/css/bootstrap.css';

const Consignment = (props) => {

    const [data, setData] = useState([]);
    const [clientData, setClientData] = useState([]);
    const [daybookFile, setDaybookFile] = useState([]);
    const [id, setId] = useState([]);
    const [fileUrl, setFileUrl] = useState([]);


    const [ledgerAmount] = useState(0)
    const navigate = useNavigate();
    const firebase = useFirebase();
    const { state } = useLocation();

    useEffect(() => {
        if (data && data.length <= 0) {
            firebase.getSubCollectionData(state.name)
                .then(collect => {
                    setData(collect)
                });
        }
        console.log(data)
    }, [state.name, data, firebase])

    useEffect(() => {
        if (clientData.length <= 0) {
            firebase.getData("clients")
                .then(collect => {
                    setClientData(collect.docs)
                });
        }
    }, [clientData, firebase])

    useEffect(() => {
        if (data.length > 0 && clientData.length > 0) {
            const matchingClient = clientData.find(clientItem =>
                data.some(item => item.data().email === clientItem.data().email)
            );
            if (matchingClient) {
                setFileUrl(matchingClient.data().fileUrl);
                setDaybookFile(matchingClient.data().fileName);
            }
        }
    }, [data, clientData]);

    const signOutUSer = () => {
        firebase.signOutUser();
        navigate("/")
    }

    const downloadFile = async (id, fileUrl, fileName) => {
        const urls = await firebase.getFile(fileUrl)
        console.log(urls)
        const aElement = document.createElement('a');
        aElement.href = urls;
        aElement.setAttribute('download', fileName);
        aElement.setAttribute('target', '_blank');
        aElement.click();
        aElement.remove();
    }

    const downloadDaybookFile = async (id, fileUrl, fileName) => {
        const urls = await firebase.getFile(fileUrl)
        console.log(urls)
        const aElement = document.createElement('a');
        aElement.href = urls;
        aElement.setAttribute('download', fileName);
        aElement.setAttribute('target', '_blank');
        aElement.click();
        aElement.remove();
    }


    return (
        <div className="consignComp">
            <h4> {state.name} Consignment Details Are:</h4>
            <h4> Client Daybook Details:  <td><Button onClick={() => downloadDaybookFile(id, fileUrl, daybookFile)} style={{
                background: "none",
                color: "black", border: "none"
            }}>{daybookFile}</Button></td></h4>
            {(props.user.email === "admin@gmail.com")}
            {data && data.length < 0 &&
                <div> No consignment details present</div>}
            {data.length > 0 && <Table striped bordered hover responsive>
                <thead>
                    <tr>
                        <th>Sr.No</th>
                        <th>Name</th>
                        <th>Email</th>
                        <th>Date of Arrival</th>
                        <th>Mark</th>
                        <th>Number of Cartons</th>
                        <th>Size</th>
                        <th>Weight</th>
                        <th>File</th>
                        <th>Remarks</th>
                    </tr>
                </thead>
                <tbody>
                    {data.map((item, index) => {
                        return (
                            <tr key={index}>
                                <td>{index + 1}</td>
                                <td>{item.data().name}</td>
                                <td>{item.data().email}</td>
                                <td>{item.data().dateOfArrival}</td>
                                <td>{item.data().id}</td>
                                <td>{item.data().noc}</td>
                                <td>{item.data().size}</td>
                                <td>{item.data().weight}</td>

                                <td><Button onClick={() => downloadFile(item.id, item.data().fileUrl, item.data().fileName)} style={{
                                    background: "none",
                                    color: "black", border: "none"
                                }}>{item.data().fileName}</Button></td>
                                <td>{item.data().remarks}</td>
                            </tr>
                        )
                    })}
                </tbody>
            </Table>}
            <Button className="btn btn-danger" onClick={signOutUSer} style={{ marginRight: "10px", marginBottom: "10px" }}>Log Out</Button>
        </div>
    )
}

export default Consignment;