import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import { useNavigate } from 'react-router-dom';
import Form from 'react-bootstrap/Form';
import { useFirebase } from '../../context/firebase'

import './container.css'
import 'bootstrap/dist/css/bootstrap.css';

function AddContainer() {

    const [container, setContainer] = useState('');
    const [carton, setCarton] = useState('');
    const [date, setDate] = useState('');

    const firebase = useFirebase();
    const navigate = useNavigate();

    const handleSubmit = async (e) => {
        e.preventDefault();
        await firebase.handleCreateNewContainer(container, date, carton)
        navigate(`/container`)
    }

    return (
        <div className="containerComp">Container
            <Form onSubmit={handleSubmit}>
                <Form.Group className="mb-3">
                    <Form.Label>Enter Container Number</Form.Label>
                    <Form.Control type="text" placeholder="Container No" onChange={(e) => setContainer(e.target.value)} value={container} />
                </Form.Group>
                <Form.Group className="mb-3">
                    <Form.Label>Date of Arrival</Form.Label>
                    <Form.Control type="date" placeholder="Date" onChange={(e) => setDate(e.target.value)} value={date} />
                </Form.Group>
                <Form.Group className="mb-3">
                    <Form.Label>Total Number of Cartons</Form.Label>
                    <Form.Control type="text" placeholder="Carton No" onChange={(e) => setCarton(e.target.value)} value={carton} />
                </Form.Group>
                <Button variant="primary" type="submit">
                    Add Container
                </Button>
                <Button variant="primary" onClick={() => navigate(`/container`)} style={{ marginLeft: "10px" }}>
                    Cancel
                </Button>
            </Form>
        </div>
    )
}

export default AddContainer;