import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Table from 'react-bootstrap/Table';
import Button from 'react-bootstrap/Button';
import Accordion from 'react-bootstrap/Accordion';
import { useAccordionButton } from 'react-bootstrap/AccordionButton';
import Card from 'react-bootstrap/Card';
import trash from '../../assets/trash.svg';
import edit from '../../assets/pen.svg';
import { useFirebase } from '../../context/firebase';

import './container.css'
import 'bootstrap/dist/css/bootstrap.css';

function CustomToggle({ children, eventKey }) {
    const decoratedOnClick = useAccordionButton(eventKey, () =>
        console.log('totally custom!',children),
    );

    return (
        <button
            type="button"
            style={{ border: "none", background: "none", width: "100%", minWidth: "100%" }}
            onClick={decoratedOnClick}
        >
            {children}
        </button>
    );
}

const Container = () => {

    const [data, setData] = useState([]);
    const [contData, setContData] = useState([]);
    const [clientData, setClientData] = useState([]);
    const [isEditing, setIsEditing] = useState(false)
    const [isTextEditing, setIsTextEditing] = useState(false)
    const [openId, setOpenId] = useState();
    const [isDisabled, setIsDisabled] = useState(true)

    const navigate = useNavigate();
    const firebase = useFirebase();

    useEffect(() => {
        if (data.length <= 0) {
            firebase.getData("container")
                .then(collect => {
                    //console.log(docs.docs[0].data())
                    setData(collect.docs)
                });
        }
        if (clientData.length <= 0) {
            firebase.getData("clients")
                .then(collect => {
                    //console.log(docs.docs[0].data())
                    setClientData(collect.docs)
                });
        }

    }, [clientData, data, firebase])

    const getPageData = () => {
        firebase.getData("container")
            .then(collect => {
                //console.log(docs.docs[0].data())
                setData(collect.docs)
            });
    }

    const onEditClicked = (contitem, item) => {
        const obj = {
            item: contitem.data(),
            containerid: contitem.id,
            clientid: item.id
        }
        navigate(`/container/addContainerClients`, { state: { obj } })
    }

    const onDeleteClicked = async (contitem, item) => {
        await firebase.deleteContainerClient(contitem.data().entryCid, item, contitem)
            .then(() => {
                getContData(item.id)
            })
    }

    const onDeleteContainer = async (item) => {
        await firebase.deleteDocument('container', item, 'containerClients')
        getPageData();
    }

    const signOutUSer = () => {
        firebase.signOutUser();
        navigate("/")
    }

    const downloadFile = async (id, fileUrl, fileName) => {
        const urls = await firebase.getFile(fileUrl)
        console.log(urls)
        const aElement = document.createElement('a');
        aElement.href = urls;
        aElement.setAttribute('download', fileName);
        aElement.setAttribute('target', '_blank');
        aElement.click();
        aElement.remove();
    }

    const addNew = (id, date, container) => {
        console.log(date, container)
        var emailArr = [], nameArr = [];
        clientData.forEach((item) => {
            emailArr.push(item.data().email)
            nameArr.push(item.data().name)
        })
        navigate(`/container/addContainerClients`, { state: { click: "addClicked", id: id, emails: emailArr, names: nameArr, dateOfArrival: date,containerName:container } });
    }

    const getContData = (contId) => {
        setContData([]);
        firebase.getSubContainerData(contId)
            .then(collect => {
                setContData(collect.docs)
            });
    }

    const handleDateChange = async (e, containerId, containerName) => {
        console.log(containerId, containerName)
        await firebase.getDateChanged(e.target.value, containerId)
        await firebase.updateClientDOA(e.target.value, containerName)
        setIsEditing(false)
        setOpenId();
        getPageData();
    }

    const handleNumberChange = async (e, containerId) => {
        e.preventDefault();
        await firebase.getNumberChanged(e.target.elements.text.value, containerId)
        setIsEditing(false)
        setOpenId();
        getPageData();
    }

    const onDeleteContclients = async (docId) => {
        await firebase.deleteClients(docId)
            .then(() => {
                getPageData()
                setIsDisabled(false)
            });
    }

    function calculateTotal(columnName) {
        let total = 0;
        for (const contitem of contData) {
            total += parseInt(contitem.data()[columnName]);
        }
        return total;
    }

    return (
        <div className="containerComp">
            <h4> Your Container Details are:</h4>
            <Button onClick={() => navigate("/table")} style={{ marginRight: "10px", marginBottom: "10px" }}>Back to Clients</Button>
            <Button onClick={() => navigate('/container/addContainer')} style={{ marginRight: "10px", marginBottom: "10px" }}>New Container</Button>

            {data.length <= 0 &&
                <div> No records present</div>}
            <Accordion>
                {data.map((item, key) => {
                    return (
                        <Card key={key} onClick={() => getContData(item.id)}>
                            <Card.Header>
                                <CustomToggle eventKey={key}>
                                    <div>
                                        {
                                            (isTextEditing && key === openId) ?
                                                <form onSubmit={(e) => handleNumberChange(e, item.id)}>
                                                    <label style={{ marginRight: "10px" }}>Container No</label>
                                                    <input type='text' defaultValue={item.data().container} name='text' style={{ marginRight: "10px" }} />
                                                    <Button type="submit">Submit</Button>
                                                    <Button type="secondary" onClick={() => setIsTextEditing(false)}>Cancel</Button>
                                                </form>
                                                : <h4 onDoubleClick={() => { setIsTextEditing(true); setOpenId(key) }}>Container No: {item.data().container}</h4>
                                        }
                                        {
                                            (isEditing && key === openId) ?
                                                <form>
                                                    <label style={{ marginRight: "10px" }}>Date of Arrival</label>
                                                    <input type='date' defaultValue={item.data().date} onChange={(e) => handleDateChange(e, item.id, item.data().container)} style={{ marginRight: "10px" }} />
                                                    <Button type="secondary" onClick={() => setIsEditing(false)}>Cancel</Button>
                                                </form>
                                                : <p onDoubleClick={() => { setIsEditing(true); setOpenId(key) }}>Date of Arrival: {item.data().date}</p>
                                        }
                                        {/* <p>Total No of Cartons: {calculateTotal('noc')}</p> */}
                                        <Button onClick={() => addNew(item.id, item.data().date, item.data().container)} style={{ marginRight: "10px" }}>Add new</Button>
                                        <Button onClick={() => onDeleteContainer(item.id)} disabled={isDisabled} style={{ marginRight: "10px" }}>Delete Container</Button>
                                        <Button onClick={() => onDeleteContclients(item.id)}>Delete all Clients</Button>
                                    </div>
                                </CustomToggle>
                            </Card.Header>
                            <Accordion.Collapse eventKey={key}>
                                <Card.Body>
                                    <Table striped bordered hover responsive>
                                        <thead>
                                            <tr>
                                                <th>Name of the user</th>
                                                <th>Mark</th>
                                                <th>CBM</th>
                                                <th>Weight</th>
                                                <th>Total CTN</th>
                                                <th>File</th>
                                                <th>Remarks</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {contData.map((contitem) => {
                                                return (
                                                    <tr key={contitem.data().id}>
                                                        <td>{contitem.data().name}</td>
                                                        <td>{contitem.data().id}</td>
                                                        <td>{contitem.data().size}</td>
                                                        <td>{contitem.data().weight}</td>
                                                        <td>{contitem.data().noc}</td>
                                                        <td><Button onClick={() => downloadFile(contitem.id, contitem.data().fileUrl, contitem.data().fileName)} style={{
                                                            background: "none",
                                                            color: "black", border: "none"
                                                        }}>{contitem.data().fileName}</Button></td>
                                                        <td>{contitem.data().remarks}</td>
                                                        <td><Button onClick={() => onEditClicked(contitem, item)} style={{ background: "none", border: "none" }}><img src={edit} alt='Edit' /></Button></td>
                                                        <td><Button onClick={() => onDeleteClicked(contitem, item)} style={{ background: "none", border: "none" }}><img src={trash} alt='Delete' /></Button></td>
                                                    </tr>
                                                )
                                            })}
                                            <tr>
                                                <td colSpan="2">Total:</td>
                                                <td>{calculateTotal('size')}</td>
                                                <td>{calculateTotal('weight')}</td>
                                                <td>{calculateTotal('noc')}</td>
                                                {/* <td colSpan="3"></td> Colspan for the file name, remarks, edit, and delete buttons */}
                                            </tr>
                                        </tbody>
                                    </Table>
                                </Card.Body>
                            </Accordion.Collapse>
                        </Card>)
                }
                )}
            </Accordion>
            <Button className="btn btn-danger" onClick={signOutUSer} style={{ marginRight: "10px", marginTop: "10px" }}>Log Out</Button>
        </div>
    )
}

export default Container;