import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { useFirebase } from '../../context/firebase'

import 'bootstrap/dist/css/bootstrap.css';
import "./login.css"

const Login = (props) => {

    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");

    const navigate = useNavigate();
    const firebase = useFirebase();


    const handleSubmit = async (e) => {
        e.preventDefault();
        await firebase.signInUser(email, password)
            .then(() => {
                if (email !== "admin@gmail.com") {
                    firebase.getClientId(email)
                        .then((result) => {
                            if (result !== undefined) {
                                navigate(`/consignment/${result.name}`, { state: { name: result.name, ledgerAmount: result.ledgerAmount } })
                            }
                        })
                        .catch((err) => {
                            alert("No Records Found for this user!")
                        })
                } else {
                    navigate('/table')
                }
            })
            .catch((err) => {
                if (err.message === 'Firebase: Error (auth/wrong-password).')
                    alert("Wrong password!")
            })
    }

    const renderedForm = (
        <Form onSubmit={handleSubmit}>
            <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Label>Email address</Form.Label>
                <Form.Control type="email" placeholder="Enter email" onChange={(e) => setEmail(e.target.value)} required />
                <Form.Text className="text-muted">
                    We'll never share your email with anyone else.
                </Form.Text>
            </Form.Group>

            <Form.Group className="mb-3" controlId="formBasicPassword">
                <Form.Label>Password</Form.Label>
                <Form.Control type="password" placeholder="Password" onChange={(e) => setPassword(e.target.value)} required />
            </Form.Group>
            <Button variant="primary" type="Submit" style={{ marginRight: "10px", marginBottom: "10px" }}>
                Submit
            </Button>
        </Form>
    )

    return (
        <>
            <br />
            <div className="login-form">
                <div className="title">Login</div>
                {renderedForm}
            </div>
        </>
    );
};

export default Login;