import React, { useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { useFirebase } from '../../context/firebase';
import { useLocation, useNavigate } from "react-router-dom";

import "./tableComponent.css"
import 'bootstrap/dist/css/bootstrap.css';

function AddClient(props) {

    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [id, setId] = useState('');
    const [status, setStatus] = useState('');
    const [git, setGit] = useState('');
    const [namePresent, setNamePresent] = useState(false);
    const [emailPresent, setEmailPresent] = useState(false);
    const [file, setFile] = useState('');
    const [fileName, setFileName] = useState('');

    const firebase = useFirebase();
    const navigate = useNavigate();
    const { state } = useLocation();

    useEffect(() => {
        if (state.click !== "addClicked") {
            setName(state.obj.item.name)
            setEmail(state.obj.item.email)
            setId(state.obj.item.id)
            setStatus(state.obj.item.status)
            setGit(state.obj.item.git)
            setFile(state.obj.item.file)
            setFileName(state.obj.item.fileName)
        }
    }, [state])

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (email !== "") {
            await firebase.handleAddNewClient(name, email, id, status, file, fileName, git)
            navigate("/table")
        }
    }

    const onEdit = async () => {
        await firebase.onEditClientFields(name, state.obj.clientid, id, status, file, fileName, git, props.user)
        navigate("/table")
    }

    const checkNameAvailability = (name) => {
        firebase.checkNameAvailability(name)
            .then((res) => {
                setNamePresent(res.length > 0)
            })
    }

    const checkEmailAvailability = (email) => {
        firebase.checkEmailAvailability(email)
            .then((res) => {
                setEmailPresent(res.length > 0)
            })
    }

    return (
        <Form onSubmit={handleSubmit} className='tableComp mt-3'>
            <Form.Group className="mb-3">
                <Form.Label>Enter Client Name</Form.Label>
                <Form.Control type="text" placeholder="Name" onChange={(e) => { checkNameAvailability(e.target.value); setName((e.target.value)) }} value={name} />
                {namePresent && <Form.Label style={{ color: 'red' }}>Name already present!</Form.Label>}
            </Form.Group>

            {state.click === "addClicked" && <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Label>Enter Client E-mail</Form.Label>
                <Form.Control type="text" placeholder="Email" onChange={(e) => { checkEmailAvailability(e.target.value); setEmail((e.target.value).toLowerCase()) }} value={email} />
                {emailPresent && <Form.Label style={{ color: 'red' }}>Email already present!</Form.Label>}
            </Form.Group>}

            {state.click !== "addClicked" && <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Label>Enter Client E-mail</Form.Label>
                <Form.Control type="text" placeholder="Email" onChange={(e) => { checkEmailAvailability(e.target.value); setEmail((e.target.value).toLowerCase()) }} value={email} disabled />
                {emailPresent && <Form.Label style={{ color: 'red' }}>Email already present!</Form.Label>}
            </Form.Group>}

            <Form.Group className="mb-3" >
                <Form.Label>Client ID</Form.Label>
                <Form.Control type="text" placeholder="ID" onChange={(e) => setId(e.target.value)} value={id} />
            </Form.Group>

            <Form.Group className="mb-3" >
                <Form.Label>Status</Form.Label>
                <select className="form-select" aria-label="Default select example" onChange={(e) => setStatus(e.target.value)} value={status}>
                    <option >Select Status of client</option>
                    <option value="active">Active</option>
                    <option value="inactive">Inactive</option>
                </select>
            </Form.Group>

            <Form.Group className="mb-3" >
                <Form.Label>File</Form.Label>
                <Form.Control type="file" onChange={(e) => { setFile(e.target.files[0]); setFileName(Date.now() + '-' + e.target.files[0].name) }} />
            </Form.Group>

            <Form.Group className="mb-3">
                <Form.Label>Goods in transit</Form.Label>
                <Form.Control type="text" placeholder="GIT" onChange={(e) => setGit(e.target.value)} value={git} />
            </Form.Group>

            {state.click === "addClicked" && <Button variant="primary" type="submit" disabled={(namePresent || emailPresent)}>
                Add New Client
            </Button>}
            {state.click !== "addClicked" && <Button onClick={() => onEdit()} disabled={(namePresent || emailPresent)}>
                Edit
            </Button>}
            <Button variant="primary" onClick={() => navigate("/table")} style={{ marginLeft: "10px" }}>
                Cancel
            </Button>
        </Form>
    );
}

export default AddClient;