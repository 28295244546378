import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { useFirebase } from '../../context/firebase'

import 'bootstrap/dist/css/bootstrap.css';

const Signup = (props) => {

    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [emailPresent, setEmailPresent] = useState(false);

    const navigate = useNavigate();
    const firebase = useFirebase();

    const handleSubmit = async (e) => {
        e.preventDefault();
        await firebase.signupUser(email, password)
            .then((res) => {
                if (res !== undefined)
                navigate('/')
            })
            .catch((error) => {
                setEmailPresent(true);
            })
    }

    const renderedForm = (
        <Form onSubmit={handleSubmit}>
            <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Label>Email address</Form.Label>
                <Form.Control type="email" placeholder="Enter email" onChange={(e) => {setEmail(e.target.value)}} required />
            </Form.Group>

            <Form.Group className="mb-3" controlId="formBasicPassword">
                <Form.Label>Password</Form.Label>
                <Form.Control type="password" placeholder="Password" onChange={(e) => setPassword(e.target.value)} required />
            </Form.Group>
            <Button variant="primary" type="Submit" disabled={emailPresent}>
                Submit
            </Button>
        </Form >
    )

    return (
        <>
            <br />
            <div className="login-form">
                <div className="title">Sign Up</div>
                {renderedForm}
            </div>
        </>
    )
}

export default Signup;