import React, { useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import { useNavigate, useLocation } from 'react-router-dom';
import Form from 'react-bootstrap/Form';
import { useFirebase } from '../../context/firebase'

import '../ContainerComponent/container.css'
import 'bootstrap/dist/css/bootstrap.css';

const AddDaybook = () => {

    const [pdate, setPdate] = useState();
    const [particulars, setParticulars] = useState();
    const [paid, setpaid] = useState();
    const [rdate, setRdate] = useState();
    const [mode, setMode] = useState();
    const [received, setReceived] = useState();
    const [custName, setCustName] = useState();

    const firebase = useFirebase();
    const navigate = useNavigate();
    const { state } = useLocation();

    useEffect(() => {
        if (state.click !== "addClick") {
            setCustName(state.item.custName)
            setPdate(state.item.pdate)
            setParticulars(state.item.particulars)
            setpaid(state.item.paid)
            setRdate(state.item.rdate)
            setMode(state.item.mode)
            setReceived(state.item.received)
        }
    }, [state])

    const handleSubmit = async (e) => {
        e.preventDefault();
        firebase.handleNewDaybookEntries(custName, pdate, particulars, paid, rdate, mode, received, state.entryDate)
        navigate("/daybook", { state: { names: state.names, date: state.entryDate } })
    }

    const onEdit = async () => {
        await firebase.onEditDaybook(custName, pdate, particulars, paid, rdate, mode, received, state.date, state.entryId)
        navigate("/daybook", {state: {names: state.names, date: state.date}})
      }

    return (
        <div className="containerComp">Daybook Entry
            <Form onSubmit={handleSubmit}>
                <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label>Customer Name</Form.Label>
                    {state.names && <select className="form-select" aria-label="Default select example" onChange={(e) => setCustName(e.target.value)} value={custName}>
                        <option >Select name of client</option>
                        {state.names && state.names.map((e, key) => {
                            return <option key={key} value={e.value}>{e}</option>;
                        })}
                    </select>}
                </Form.Group>
                <Form.Group className="mb-3">
                    <Form.Label>Enter Paid Date</Form.Label>
                    <Form.Control type="date" placeholder="Paid Date" onChange={(e) => setPdate(e.target.value)} value={pdate} />
                </Form.Group>
                <Form.Group className="mb-3">
                    <Form.Label>Particulars</Form.Label>
                    <Form.Control type="text" placeholder="particulars" onChange={(e) => setParticulars(e.target.value)} value={particulars} />
                </Form.Group>
                <Form.Group className="mb-3">
                    <Form.Label>Amount Paid(in Rs.)</Form.Label>
                    <Form.Control type="text" placeholder="amount paid" onChange={(e) => setpaid(e.target.value)} value={paid} />
                </Form.Group>
                <Form.Group className="mb-3">
                    <Form.Label>Enter Received Date</Form.Label>
                    <Form.Control type="date" placeholder="Received Date" onChange={(e) => setRdate(e.target.value)} value={rdate} />
                </Form.Group>
                <Form.Group className="mb-3">
                    <Form.Label>Mode of payment</Form.Label>
                    <Form.Control type="text" placeholder="mode" onChange={(e) => setMode(e.target.value)} value={mode} />
                </Form.Group>
                <Form.Group className="mb-3">
                    <Form.Label>Amount Received(in Rs.)</Form.Label>
                    <Form.Control type="text" placeholder="amount received" onChange={(e) => setReceived(e.target.value)} value={received} />
                </Form.Group>
                {state.click === "addClick" && <Button variant="primary" type="submit">
                    Add Daybook Entry
                </Button>}
                {state.click !== "addClick" && <Button onClick={() => onEdit()}>
                    Edit Daybook
                </Button>}
                <Button variant="primary" onClick={() => navigate(`/daybook`, { state: { names: state.names, date: state.date } })}>
                    Cancel
                </Button>
            </Form>
        </div>
    )
}

export default AddDaybook