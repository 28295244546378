import React, { useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";
import Table from 'react-bootstrap/Table';
import Button from 'react-bootstrap/Button';
import { useFirebase } from '../../context/firebase';
import axios from 'axios';
import trash from '../../assets/trash.svg';
import edit from '../../assets/pen.svg';

import 'bootstrap/dist/css/bootstrap.css';
import "./tableComponent.css"

const Customer = (props) => {

    const [data, setData] = useState([]);

    const navigate = useNavigate();
    const firebase = useFirebase();

    useEffect(() => {
        if (data.length <= 0) {
            firebase.getData("clients")
                .then(collect => {
                    setData(collect.docs)
                });
        }
    }, [data, firebase])

    const getAllData = () => {
        firebase.getData("clients")
            .then(collect => {
                //console.log(docs.docs[0].data())
                setData(collect.docs)
            });
    }

    const signOutUSer = () => {
        firebase.signOutUser();
        navigate("/")
    }

    const onEditClicked = (item) => {
        const obj = {
            item: item.data(),
            clientid: item.id
        }
        navigate(`/table/addClient`, { state: { obj } })
    }

    const onDeleteClicked = async (item) => {
        const confirmed = window.confirm("Are you sure you want to delete this client?");

        if (confirmed) {
            // const deleteEmail = item.data().email;
            // await firebase.deleteUserByEmail(deleteEmail)
            await firebase.deleteDocument('clients', item.id)
            getAllData();
        }
    }


    const navigateToConsignment = (id, name, ledgerAmount) => {
        navigate(`/consignment/${id}`, { state: { name, ledgerAmount } });
    }

    const navigateToContainer = () => {
        var emails = [];
        data.forEach((item) => {
            emails.push(item.data().email)
        })
        navigate('/container', { state: { emails } })
    }

    const navigateToDaybook = () => {
        var names = [];
        data.forEach((item) => {
            names.push(item.data().name)
        })
        navigate('/daybook', { state: { names } })
    }

    const downloadFile = async (id, fileUrl, fileName) => {
        const urls = await firebase.getFile(fileUrl)
        const aElement = document.createElement('a');
        aElement.href = urls;
        aElement.setAttribute('download', fileName);
        aElement.setAttribute('target', '_blank');
        aElement.click();
        aElement.remove();
    }

    return (
        <div className='tableComp'>
            <h4>Your Client Details:</h4>
            <Button variant="primary" onClick={() => navigate('/signup')} style={{ marginRight: "10px", marginBottom: "10px" }}>
                Create Account
            </Button>
            <Button onClick={navigateToContainer} style={{ marginRight: "10px", marginBottom: "10px" }}>New Container</Button>

            {data.length > 0 && <Table striped bordered hover responsive>
                <thead>
                    <tr>
                        <th>Name</th>
                        <th>id</th>
                        <th>Status</th>
                        {/* <th>Balance</th> */}
                        <th>File</th>
                        {/* <th>GIT</th> */}
                    </tr>
                </thead>
                <tbody>
                    {data.map((item) => {
                        return (
                            <tr key={item.data().id}>
                                <td onClick={() => navigateToConsignment(item.id, item.data().name, item.data().ledgerAmount)}>{item.data().name}</td>
                                <td>{item.data().id}</td>
                                <td>{item.data().status}</td>
                                {/* <td>{item.data().ledgerAmount}</td> */}
                                <td><Button onClick={() => downloadFile(item.id, item.data().fileUrl, item.data().fileName)} style={{
                                    background: "none",
                                    color: "black", border: "none"
                                }}>{item.data().fileName}</Button></td>
                                {/* <td>{item.data().git}</td> */}
                                <td><Button onClick={() => onEditClicked(item)} style={{ background: "none", border: "none" }}><img src={edit} alt='Edit' /></Button></td>
                                <td><Button onClick={() => onDeleteClicked(item)} style={{ background: "none", border: "none" }}><img src={trash} alt='Delete' /></Button></td>
                            </tr>
                        )
                    })}
                </tbody>
            </Table>}
            <Button className="btn btn-success" onClick={() => navigate('/table/addClient', { state: { click: "addClicked" } })} style={{ marginRight: "10px", marginBottom: "10px" }}>Add New Client</Button>
            <Button className="btn btn-danger" onClick={signOutUSer} style={{ marginRight: "10px", marginBottom: "10px" }}>Log Out</Button>
        </div>
    )
};


export default Customer;