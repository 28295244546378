import React, { useState, useEffect } from 'react';
import Button from 'react-bootstrap/Button';
import { useLocation, useNavigate } from 'react-router-dom';
import Form from 'react-bootstrap/Form';
import { useFirebase } from '../../context/firebase'

import './container.css'
import 'bootstrap/dist/css/bootstrap.css';

function AddContainerClients() {

  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [id, setId] = useState('');
  const [size, setSize] = useState('');
  const [weight, setWeight] = useState('');
  const [noc, setNoc] = useState('');
  const [file, setFile] = useState('');
  const [fileName, setFileName] = useState('');
  const [remarks, setRemarks] = useState('');

  const firebase = useFirebase();
  const { state } = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (state.click !== "addClicked") {
      setName(state.obj.item.name)
      setEmail(state.obj.item.email)
      setId(state.obj.item.id)
      setSize(state.obj.item.size)
      setWeight(state.obj.item.weight)
      setNoc(state.obj.item.noc)
      setFile(state.obj.item.file)
      setFileName(state.obj.item.fileName)
      setRemarks(state.obj.item.remarks)
    }
  }, [state])

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (name !== ""){
      await firebase.handleCreateNewContainerClients(name, email, id, size, weight, noc, file, remarks, fileName, state.id, state.dateOfArrival, state.containerName)
    navigate(`/container`)
    }
  }

  const setEmailWithName = async(name) => {
    setName(name);
    if(name !== undefined) {
      const emailReceived = await firebase.getEmailWithName(name)
      setEmail(emailReceived)
    }
  }

  const onEdit = async () => {
    await firebase.onEditContainerFields(name, id, size, weight, noc, file, remarks, fileName, state.obj.clientid, state.obj.containerid, state.obj.item.entryCid)
    navigate(`/container`)
  }

  return (
    <Form onSubmit={handleSubmit} className="containerComp">
      {state.click === "addClicked" && <Form.Group className="mb-3" controlId="formBasicEmail">
        <Form.Label>Enter name of the client</Form.Label>
        <select className="form-select" aria-label="Default select example" onChange={(e) => setEmailWithName(e.target.value)} value={name}>
          <option >Select name of client</option>
          {state.names.map((e, key) => {
            return <option key={key} value={e.value}>{e}</option>;
          })}
        </select>
      </Form.Group>}
      {state.click !== "addClicked" && <Form.Group className="mb-3" controlId="formBasicEmail">
        <Form.Label>Enter name of user</Form.Label>
        <Form.Control type="text" placeholder="Name" onChange={(e) => setEmailWithName(e.target.value)} value={name} />
      </Form.Group>}
      {state.click === "addClicked" && <Form.Group className="mb-3" controlId="formBasicEmail">
        <Form.Label>Enter email of the client</Form.Label>
        <select className="form-select" aria-label="Default select example" onChange={(e) => setEmail(e.target.value)} value={email}>
          <option >Select Email of client</option>
          {state.emails.map((e, key) => {
            return <option key={key} value={e.value}>{e}</option>;
          })}
        </select>
      </Form.Group>}

      {state.click !== "addClicked" && <Form.Group className="mb-3" controlId="formBasicEmail">
        <Form.Label>Enter email of the client</Form.Label>
        <Form.Control type="text" placeholder="Email" onChange={(e) => setEmail(e.target.value)} value={email} disabled />
      </Form.Group>}

      <Form.Group className="mb-3" controlId="formBasicPassword">
        <Form.Label>Mark</Form.Label>
        <Form.Control type="text" placeholder="Mark" onChange={(e) => setId(e.target.value)} value={id} />
      </Form.Group>

      <Form.Group className="mb-3" >
        <Form.Label>CBM</Form.Label>
        <Form.Control type="text" placeholder="CBM" onChange={(e) => setSize(e.target.value)} value={size} />
      </Form.Group>

      <Form.Group className="mb-3" >
        <Form.Label>Weight</Form.Label>
        <Form.Control type="text" placeholder="Weight" onChange={(e) => setWeight(e.target.value)} value={weight} />
      </Form.Group>

      <Form.Group className="mb-3" >
        <Form.Label>Total CTN</Form.Label>
        <Form.Control type="text" placeholder="Total CTN" onChange={(e) => setNoc(e.target.value)} value={noc} />
      </Form.Group>

      <Form.Group className="mb-3" >
        <Form.Label>File</Form.Label>
        <Form.Control type="file" onChange={(e) => {setFile(e.target.files[0]); setFileName(Date.now() + '-' + e.target.files[0].name)}} />
      </Form.Group>

      <Form.Group className="mb-3">
        <Form.Label>Remarks</Form.Label>
        <Form.Control type="text" placeholder="Remarks" onChange={(e) => setRemarks(e.target.value)} value={remarks} />
      </Form.Group>
      {state.click === "addClicked" && <Button variant="primary" type="submit">
        Create Container
      </Button>}
      {state.click !== "addClicked" && <Button onClick={() => { onEdit() }}>
        Edit Container
      </Button>}
      <Button variant="primary" onClick={() => navigate(`/container`)} style={{marginLeft: "10px"}}>
        Cancel
      </Button>
    </Form>
  );
}

export default AddContainerClients;